import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDModal from 'components/MDModal'
import MDTypography from 'components/MDTypography'
import { useAppServices } from 'hook/services'
import React, { useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useLocation } from 'react-router-dom'
import { TextareaAutosize } from '@mui/material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px  #000',
  boxShadow: 24,
  overflow: 'unset',
  p: 4,
}
const DocsValidate = yup.object({
  name: yup.string().required().label('Name'),
})
function Category({ open, onClose }) {
  const { state } = useLocation()
  const [processing, setProcessing] = useState(false)
  const AppService = useAppServices()
  const handleSubmit = async (e) => {
    setProcessing(true)
    e.preventDefault()
    const payload = {
      name: e.target.name.value,
      description: e.target.description.value,
      category_id: state._id,
      type: 'super-admin',
    }
    const { response } = await AppService.agency_articles_sub_category.create({
      payload,
      toaster: true,
    })
    setProcessing(false)
    if (response) {
      onClose()
    }
  }
  const initstate = {
    name: '',
  }

  const formik = useFormik({
    initialValues: { ...initstate },
    onSubmit: handleSubmit,
    validationSchema: DocsValidate,
  })
  return (
    <MDBox>
      <MDModal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox role="form" component="form" onSubmit={handleSubmit} sx={style}>
          <MDTypography variant="h6">Create Docs Category</MDTypography>
          <MDBox my={2}>
            <MDInput
              type="text"
              label="Name"
              name="name"
              onChange={formik.handleChange}
              inputProps={{ onFocus: formik.handleBlur }}
              value={formik.values.name}
              error={formik.touched.name && formik.errors.name}
              helperText={formik.touched.name && formik.errors.name ? formik.errors.name : ''}
              success={formik.touched.name && !formik.errors.name}
              fullWidth
            />
          </MDBox>
          <MDBox my={2}>
            <TextareaAutosize
              className="form-control"
              style={{ minHeight: '70px' }}
              placeholder="Description"
              name="description"
            />
          </MDBox>
          <MDButton
            variant="gradient"
            color="info"
            type="submit"
            className="brand-card-background"
            loading={processing}
            disabled={processing || !formik.isValid}
            sx={{ mt: 4, mb: 1 }}
            fullWidth
          >
            {'Add'}
          </MDButton>
        </MDBox>
      </MDModal>
    </MDBox>
  )
}

export default Category
