import MDModal from 'components/MDModal';
import React, { useState, useEffect } from 'react';
import "./style.css";
import { useAgencyInfo } from 'context/agency';
import { useAppServices } from 'hook/services';
import ReactSelect from 'react-select';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

function CreateTag({ openModel, onclosemodel, data, modelfunctionality }) {
  const [agency] = useAgencyInfo();
  const AppService = useAppServices();
  const [processing, setProcessing] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    categories: '',
  });
  const [categories, setCategories] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px  #000',
    p: 4,
    overflow: "hidden",
  }

  useEffect(() => {
    if (data) {
      setFormData({
        name: data.title || '',
        category: data.category || '',
      });
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let ids = selectedOptions.map(cat => cat.value);

    const payload = { ...formData, categories: ids }
    modelfunctionality(payload);
  };

  return (
    <MDBox>
      <MDModal
        open={openModel}
        onClose={onclosemodel}
        height={250}
        width={500}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          backgroundColor: "transparent",
        }}
      >
        <MDBox role="form" component="form" onSubmit={handleSubmit} sx={style}>
          <MDTypography variant="h6">Create Tag</MDTypography>
          <MDBox my={1}>
            <label className="text-black" style={{ display: "flex", alignItems: "center" }} >
              <span style={{ fontSize: "14px" }}>Tag Title</span>
            </label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="text" style={{ width: "100%" }} name='name' defaultValue={formData.name} onChange={handleChange} />

            </div>
          </MDBox>

          <MDButton
            variant="gradient"
            color="info"
            type="submit"
            className="brand-card-background"
            loading={processing}
            disabled={processing}
            sx={{ mt: 1, mb: 1 }}
            fullWidth
          >
            Save
          </MDButton>
        </MDBox>
      </MDModal>
    </MDBox>
    // <div>
    //   {openModel &&
    //     <div className="modal-zz-confirm text-left"  >

    //       <div className="scrollable-modal-content bg-white  rounded" style={{ position: "relative" }}>
    //         <h2 className='text-black text-left font-14'>
    //           Create Tag
    //         </h2>
    //         <span className='crossison' onClick={onclosemodel}>X</span>
    //         <div>
    //           {data && (
    //             <h2 className='font14'>
    //               <span className='new_dezin_label text-black '>Prompt: </span>{data.title}
    //             </h2>
    //           )}
    //           <form onSubmit={handleSubmit}>
    //             <div className='w-100 margin_fix'>
    //               <div>
    //                 <label className='new_dezin_label text-black '>Tag Title</label>
    //               </div>
    //               <div>
    //                 <input
    //                   type="text"
    //                   name='name'
    //                   className='w-100 form-control-agust'
    //                   value={formData.name}
    //                   onChange={handleChange}
    //                 />
    //               </div>
    //             </div>
    //             <div className='w-100 margin-top-20px'>
    //               <button type='submit' className='btn-newdezine'>Save</button>
    //             </div>
    //           </form>
    //         </div>
    //       </div>
    //     </div>}
    // </div>
  );
}

export default CreateTag;
