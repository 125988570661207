import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDModal from 'components/MDModal'
import MDTypography from 'components/MDTypography'
import { useAppServices, useUploadImage } from 'hook/services'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useAgencyInfo } from "context/agency";
import Multiselect from './components/Multiselect'
import { TextareaAutosize } from '@mui/material'
import infoimg from "../info.png"
import ReactSelect from 'react-select'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 500,
  bgcolor: 'background.paper',
  border: '2px  #000',
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
}
const DocsValidate = yup.object({
  name: yup.string().required().label('Name'),
})
function OptionCreate({ open, onClose, options, prices }) {

  const [agency, Update] = useAgencyInfo();
  const [processing, setProcessing] = useState(false)
  const AppService = useAppServices();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true)
    const create_payload = {
      name: e.target.name.value,
      plan_id: e.target.plan_id.value,
      charge_api: e.target.charge_api.value,
    };
    const update_payload = {
      name: e.target.name.value,
      plan_id: e.target.plan_id.value,
      charge_api: e.target.charge_api.value,
      _id: options._id
    };
    const payload = options._id ? update_payload : create_payload;
    const plan = await AppService.plan[
      options?._id ? 'update' : 'create'
    ]({
      payload,
      toaster: true,
    })
    if (plan) {
      setProcessing(false)
      onClose()
      runAPIofStoreAsisstant(payload);
    }
    // Submit logic here, e.g., calling API with AppService
  };


  return (
    <MDBox>
      <MDModal
        open={open}
        onClose={onClose}
        height={400}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox role="form" component="form" onSubmit={handleSubmit} sx={style}>
          <MDTypography variant="h6">Create Plan</MDTypography>
          <MDBox my={2}>
            <label className="text-black" style={{ display: "flex", alignItems: "center" }} >
              <span>Name</span> <img style={{ width: "30px" }} src={infoimg} /></label>

            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="text" style={{ width: "100%" }} name='name' defaultValue={options?.name} />
            </div>
          </MDBox>
          <MDBox my={2}>
            <label className="text-black" style={{ display: "flex", alignItems: "center" }} >
              <span>plan ID</span> <img style={{ width: "30px" }} src={infoimg} /></label>

            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="text" style={{ width: "100%" }} name='plan_id' defaultValue={options?.plan_id} />

            </div>
          </MDBox>
          <MDBox my={2}>
            <label className="text-black" style={{ display: "flex", alignItems: "center" }} >
              <span>Charge Per API</span> <img style={{ width: "30px" }} src={infoimg} /></label>

            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="text" style={{ width: "100%" }} name='charge_api' defaultValue={options?.charge_api} />

            </div>
          </MDBox>

          <MDButton
            variant="gradient"
            color="info"
            type="submit"
            className="brand-card-background"
            loading={processing}
            disabled={processing}
            sx={{ mt: 4, mb: 1 }}
            fullWidth
          >
            {options?.name ? 'Update' : 'Save'}
          </MDButton>
        </MDBox>
      </MDModal>
    </MDBox>
  )
}

export default OptionCreate
