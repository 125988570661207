// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { useAppServices } from 'hook/services'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MDButton from 'components/MDButton'
import Swal from 'sweetalert2'
import { Icon } from '@mui/material'
import Category from '../Category/create'

export default function data() {
  const AppService = useAppServices()
  const [loader, setLoader] = useState(true)
  const [docs, setDocs] = useState([])
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const [sendsubcategories, setsendsubcategories] = useState([])
  const [sendprompts, setsendprompts] = useState([])
  const [promptslabel, setpromptslabel] = useState([])

  const [selected, setSelected] = useState()
  const handleOnClick = (item, idx) => {
    setOpen(idx)
    setSelected(item)
  }
  const handleDeleteCategory = async (id, idx) => {
    Swal.fire({
      title: 'Warning',
      text: 'Are you sure you want to delete?',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes',
    }).then(async (res) => {
      if (!res.isConfirmed) return ''
      const { response } = await AppService.promptslabel.Delete({
        query: `_id=${id}`,
        toaster: true,
      })
      if (response) {
        promptslabel.splice(idx, 1)
        setpromptslabel([...promptslabel])
      }
    })
  }
  const getDocs_Cat = async () => {
    const { response } = await AppService.docs.get_filter_cat({ query: 'type=super-admin' })
    if (response) {
      setDocs(response.data.prompt_category)
      setsendsubcategories(response.data.prompt_sub_category)
      setsendprompts(response.data.prompt)
    }
    setLoader(false)
  }
  const getTableData=async()=>{
    const { response } = await AppService.promptslabel.Get({ query: 'type=superadmin' })
    if (response) {
      console.log(response.data);
      setpromptslabel(response.data)
    }
    setLoader(false)
  }

  

  const onLoad = () => {
    getDocs_Cat();
    getTableData();
  }
  useEffect(onLoad, [])

  const handleClose = () => {
    setOpen(false)
    onLoad();
  }

  return {
    columns: [
      { Header: '', accessor: 'order', width: '10%', align: 'left', verticalAlign: 'baseline' },
      { Header: 'name', accessor: 'name', align: 'left', width: '10%', verticalAlign: 'baseline' },
       
      {
        Header: 'action',
        accessor: 'action',
        align: 'center',
        width: '10%',
        verticalAlign: 'baseline',
      },
    ],

    rows: promptslabel.map((item, idx) => ({
      order: (
        <MDBox sx={{ cursor: 'pointer' }}>
          <Icon>menu</Icon>
        </MDBox>
      ),
      name: (
        <div style={{ maxWidth: '200px' }}>
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            sx={{ cursor: 'pointer', fontSize: '20px' }}
          >
            {item.name}
          </MDTypography>
        </div>
      ),
     
      action: (
        <>
          <MDButton
            onClick={() => handleOnClick(item, idx)}
            variant="gradient"
            color="info"
            style={{ marginRight: '10px' }}
          >
            Edit{' '}
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            style={{ marginRight: '10px' }}
            onClick={() => handleDeleteCategory(item._id, idx)}
          >
            delete
          </MDButton>
          
          {selected == item && (
            <Category category={selected} open={open === idx} onClose={handleClose}   docs={docs} sendsubcategories={sendsubcategories} sendprompts={sendprompts} />
          )}
        </>
      ),
    })),
    getDocs_Cat,
    getTableData,
    docs,
    sendsubcategories,
    sendprompts,
    
  }
}
