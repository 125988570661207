/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import * as yup from 'yup'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// Data
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MDButton from 'components/MDButton'
import { IconButton, InputAdornment, Modal, TextField } from '@mui/material'
import MDInput from 'components/MDInput'
import { Search } from '@mui/icons-material'
import { useAppServices } from 'hook/services'
import { useFormik } from 'formik'
import DataTable from 'examples/Tables/DataTable'
import projectsTableData from '../data/projectsTableData'
import authorsTableData from '../data/authorsTableData'
import Category from './create'

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px  #000',
//   boxShadow: 24,
//   p: 4,
// }
// const initstate = {
//   name: '',
// }
// const DocsValidate = yup.object({
//   name: yup.string().required().label('Name'),
// })
function Docs() {
  const { columns, rows, getDocs_Cat,getTableData,docs,sendsubcategories,sendprompts } = authorsTableData()
  const [processing, setProcessing] = useState(false)

  const { columns: pColumns, rows: pRows } = projectsTableData()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    getDocs_Cat();
    getTableData();
    setOpen(false)
  }



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Category
                </MDTypography>
                {/* <TextField
                  sx={{ marginLeft: 'auto' }}
                  label="With normal TextField"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                /> */}
                {docs && docs.length > 0 &&
                  <>
                    <MDButton
                      onClick={() => setOpen(true)}
                      sx={{
                        marginLeft: 'auto',
                        // '10px'
                      }}
                      variant="outlined"
                    >
                      Create
                    </MDButton>

                    <Category open={open} onClose={handleClose} category={[]}  docs={docs} sendsubcategories={sendsubcategories} sendprompts={sendprompts} /></>}
              </MDBox>
              <DndProvider backend={HTML5Backend}>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </DndProvider>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default Docs
