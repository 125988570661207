/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

function configs(labels, datasets) {
  return {
    data: {
      labels,
      datasets: [
        {
          label           : datasets.label,
          tension         : 0.4,
          borderWidth     : 0,
          borderRadius    : 4,
          borderSkipped   : false,
          backgroundColor : 'rgba(255, 255, 255, 0.8)',
          data            : datasets.data,
          maxBarThickness : 6,
        },
      ],
    },
    options: {
      responsive          : true,
      maintainAspectRatio : false,
      plugins             : {
        legend: {
          display: false,
        },
      },
      interaction: {
        intersect : false,
        mode      : 'index',
      },
      scales: {
        y: {
          grid: {
            drawBorder      : false,
            display         : true,
            drawOnChartArea : true,
            drawTicks       : false,
            borderDash      : [5, 5],
            color           : 'rgba(255, 255, 255, .2)',
          },
          ticks: {
            suggestedMin : 0,
            suggestedMax : 500,
            beginAtZero  : true,
            padding      : 10,
            font         : {
              size       : 14,
              weight     : 300,
              family     : 'Roboto',
              style      : 'normal',
              lineHeight : 2,
            },
            color: '#fff',
          },
        },
        x: {
          grid: {
            drawBorder      : false,
            display         : true,
            drawOnChartArea : true,
            drawTicks       : false,
            borderDash      : [5, 5],
            color           : 'rgba(255, 255, 255, .2)',
          },
          ticks: {
            display : true,
            color   : '#f8f9fa',
            padding : 10,
            font    : {
              size       : 14,
              weight     : 300,
              family     : 'Roboto',
              style      : 'normal',
              lineHeight : 2,
            },
          },
        },
      },
    },
  }
}

export default configs
