/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React Button Styles
import root from 'assets/theme-dark/components/button/root'
import contained from 'assets/theme-dark/components/button/contained'
import outlined from 'assets/theme-dark/components/button/outlined'
import buttonText from 'assets/theme-dark/components/button/text'

const button = {
  defaultProps: {
    disableRipple: false,
  },
  styleOverrides: {
    root               : { ...root },
    contained          : { ...contained.base },
    containedSizeSmall : { ...contained.small },
    containedSizeLarge : { ...contained.large },
    containedPrimary   : { ...contained.primary },
    containedSecondary : { ...contained.secondary },
    outlined           : { ...outlined.base },
    outlinedSizeSmall  : { ...outlined.small },
    outlinedSizeLarge  : { ...outlined.large },
    outlinedPrimary    : { ...outlined.primary },
    outlinedSecondary  : { ...outlined.secondary },
    text               : { ...buttonText.base },
    textSizeSmall      : { ...buttonText.small },
    textSizeLarge      : { ...buttonText.large },
    textPrimary        : { ...buttonText.primary },
    textSecondary      : { ...buttonText.secondary },
  },
}

export default button
