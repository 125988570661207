import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDModal from 'components/MDModal';
import MDTypography from 'components/MDTypography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppServices } from 'hook/services';
import { Label } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Category({ open, category, onClose, docs, sendsubcategories, sendprompts }) {
  const [formname, setformname] = useState(category.name);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions1, setSelectedOptions1] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const [processing, setProcessing] = useState(false);
  const AppService = useAppServices()
  const getformselectvalues=async()=>{
    const { response } = await AppService.promptslabel.GetselectvaluesbyID({ query: `_id=${category._id}` })
    if (response) {
      console.log(response.data);
      
      setSelectedOptions(response.data.categoryselect)
      setSelectedOptions1(response.data.subcategoryselect)
      setSelectedOptions2(response.data.promptselect)
    }
    setLoader(false)
  } 
  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };
  const handleChange1 = (selected) => {
    setSelectedOptions1(selected);
  };
  const handleChange2 = (selected) => {
    setSelectedOptions2(selected);
  };

  const handleSubmit = async (e) => {
    setProcessing(true);
    e.preventDefault();
    try {
      // Mock submission
      const categories = selectedOptions.map(option => option.value);
      const subcategories = selectedOptions1.map(option => option.value);
      const prompts = selectedOptions2.map(option => option.value);

      let payload = {
        name: formname,
        categories: categories,
        subcategories: subcategories,
        prompts: prompts,
        type:"superadmin"
      }
      if (category?._id) {
        payload = { ...payload, _id: category._id };
      }
       ;
      const { response } = await AppService.promptslabel[
        category?._id ? 'update' : 'create'
      ]({
        payload,
        toaster: true,
      })
      if (response) {
        setSelectedOptions([])
        setSelectedOptions1([])
        setSelectedOptions2([])
        onClose();

      }
    } catch (error) {
      console.error('Failed to create category:', error);
    } finally {
      setProcessing(false);
    }
  };
useEffect(() => {
  setTimeout(() => {
    getformselectvalues();
  }, 1000);
}, [])

 

  return (
    <MDBox>
      <MDModal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >
        <MDBox component="form" style={{ overflow: "scroll" }} onSubmit={handleSubmit} sx={style}>
          <MDTypography variant="h6">{category._id ? "Edit" : "Create"} Prompts Label</MDTypography>
          <MDBox my={1}>
            <label style={{ fontSize: "15px" }}>Name</label>
            <input
              type="text"
              label="Name"
              name="name"
              className='form-control py-2'
              fullWidth
              value={formname}
              onChange={(e) => setformname(e.target.value)}
            />
          </MDBox>
          <MDBox my={1}>
            <label style={{ fontSize: "15px" }}>Categories</label>
            <Select
              isMulti

              onChange={handleChange}
              value={selectedOptions}
              options={docs?.map((item) => ({ label: item.name, value: item._id }))}
            />
          </MDBox>
          <MDBox my={1}>
            <label style={{ fontSize: "15px" }}>SubCategories</label>
            <Select
              isMulti

              onChange={handleChange1}
              value={selectedOptions1}
              options={sendsubcategories?.map((item) => ({ label: item.name, value: item._id }))}
            />
          </MDBox>
          <MDBox my={1}>
            <label style={{ fontSize: "15px" }}>Prompts</label>
            <Select
              isMulti

              onChange={handleChange2}
              value={selectedOptions2}
              options={sendprompts?.map((item) => ({ label: item.title, value: item._id }))}
            />
          </MDBox>
          <MDButton
            variant="gradient"
            color="info"
            type="submit"
            loading={processing}
            sx={{ mb: 1 }}
            fullWidth
          >
            Submit
          </MDButton>
        </MDBox>
      </MDModal>
    </MDBox>
  );
}

export default Category;
