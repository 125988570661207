import React, { useEffect, useState } from 'react';
import { useAppServices } from 'hook/services';
import Grid from "@mui/material/Grid";
import { Switch, Button, TextField } from '@mui/material';
import MDButton from 'components/MDButton';
import axios from 'axios';
import env from 'config'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Typography from '@mui/material/Typography';

const DefaultPlugins = () => {
  const [pluginsdata, setPluginsData] = useState([])
  const [activePlugins, setActivePlugins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getAllAgency, setGetAllAgency] = useState();

  const BASE_URL = `${env.API_URL}/v1`
  console.log(getAllAgency, "getAllAgency")

  useEffect(async () => {
    try {
      const response = await axios.get(BASE_URL + '/agency/all-agency');

      setGetAllAgency(response?.data?.data)
    } catch (error) {
      console.log("errorerrorerror")
    }
  }, [])


  const Service = useAppServices()
  const loadPluginData = async () => {
    const { response } = await Service.super_admin_settings.Getplugin();

    if (response) {
      setPluginsData(response?.data?.data);
    }
  };

  useEffect(() => {
    if (getAllAgency && pluginsdata.length) {
      const newActivePlugins = {};

      getAllAgency.forEach(agency => {
        if (agency.default_plugins && agency.default_plugins.length > 0) {
          const activePluginIds = agency.default_plugins.map(plugin => plugin.id);
          newActivePlugins[agency._id] = pluginsdata.filter(plugin => activePluginIds.includes(plugin.id));
        }
      });

      setActivePlugins(newActivePlugins);
    }
  }, [getAllAgency, pluginsdata]);


  const handlePluginsChange = (agencyId, plugin) => {
    setActivePlugins(prevState => {
      const agencyPlugins = prevState[agencyId] || [];
      const isActive = agencyPlugins.some(activePlugin => activePlugin.id === plugin.id);

      const updatedPlugins = isActive
        ? agencyPlugins.filter(activePlugin => activePlugin.id !== plugin.id)
        : [...agencyPlugins, { id: plugin.id, name: plugin.name }];

      return { ...prevState, [agencyId]: updatedPlugins };
    });
  };


  const AgencySubmitHandler = async (agencyId) => {
    console.log(agencyId, "data._id ")
    console.log("Submitting active plugins:", activePlugins);
    setLoading(true)
    const payload = {
      _id: agencyId,
      activePlugins: activePlugins[agencyId] || []
    }

    console.log(payload, "payload")
    try {
      const response = await axios.put(BASE_URL + '/agency/update-plugin', payload);

      console.log('Response:', response);
      setLoading(false)
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  }

  const onLoad = () => {
    loadPluginData()
  }
  useEffect(() => {
    onLoad()
  }, [])
  return (
    <>
      <div style={{ textAlign: "right", padding: "30px 0px 20px 0px" }}>

        {getAllAgency?.map((data, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>{data?.name ? data?.name : data?.domain ? data?.domain : 'Not Defined'}</Typography>
            </AccordionSummary>
            <AccordionDetails >
              {pluginsdata?.map(plugin => (
                <div style={{ marginBottom: '20px', display: "block" }} key={plugin.id}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <label className="text-black" style={{ marginRight: '10px', fontSize: "16px" }}>
                      {plugin.name.replace(/_/g, ' ').toUpperCase()}
                    </label>
                    <Switch
                      checked={(activePlugins[data._id] || []).some(activePlugin => activePlugin.id === plugin.id)}
                      onChange={() => handlePluginsChange(data._id, plugin)}
                      name={plugin.name}
                      inputProps={{ 'aria-label': `Enable ${plugin.name.replace(/_/g, ' ')}` }}
                    />

                  </div>
                </div>

              ))}

              <MDButton
                variant="gradient"
                color="info"
                onClick={() => AgencySubmitHandler(data._id)}
              >
                {loading ? "Loading..." : "Submit"}
              </MDButton>
            </AccordionDetails>
          </Accordion>
        ))}

      </div>
    </>
  )
}

export default DefaultPlugins

