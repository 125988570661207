import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDModal from 'components/MDModal'
import MDTypography from 'components/MDTypography'
import { useAppServices, useUploadImage } from 'hook/services'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useAgencyInfo } from "context/agency";
import Multiselect from './components/Multiselect'
import { TextareaAutosize, Tooltip } from '@mui/material'
import infoimg from "../info.png"
import ReactSelect from 'react-select'
import ModelPromptslabels from './components/createtag'
import { light } from '@mui/material/styles/createPalette'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 550,
  bgcolor: 'background.paper',
  border: '2px  #000',
  boxShadow: 24,
  p: 4,
  overflow: "hidden",
}
const DocsValidate = yup.object({
  name: yup.string().required().label('Name'),
})
function OptionCreate({ open, onClose, options, prices }) {



  return (
    <MDBox>
      <MDModal
        open={open}
        onClose={onClose}
        height={550}
        width={500}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          backgroundColor: "transparent",
        }}
      >
        <MDBox sx={style}>
          <MDTypography variant="h6"> Assistant Files</MDTypography>
        </MDBox>

        <ul>
          {options.assistant_files.map((item, index) => {
            <li>{item}</li>
          })}
        </ul>

      </MDModal>

    </MDBox>
  )
}

export default OptionCreate
