// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { useAppServices } from 'hook/services'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MDButton from 'components/MDButton'
import Swal from 'sweetalert2'
import { Icon } from '@mui/material'
import EditProduct from '../Product/create'

export default function data() {
  const AppService = useAppServices()
  const [loader, setLoader] = useState(true)
  const [products, setProducts] = useState([])
  const [prices, setprices] = useState([])
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const [subcategories, setSubcategories] = useState({})
  const [prompts, setPrompts] = useState({})

  const [selected, setSelected] = useState()
  const handleOnClick = (item, idx) => {
    setOpen(idx)
    setSelected(item)
  }
  const handleDelete = async (id, idx) => {
    Swal.fire({
      title: 'Warning',
      text: 'Are you sure you want to delete?',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes',
    }).then(async (res) => {
      if (!res.isConfirmed) return ''
      const { response } = await AppService.products.deleteProduct({
        query: `_id=${id}`,
        toaster: true,
      })
      if (response) {
        products.splice(idx, 1)
        setProducts([...products])
      }
    })
  }
  const getProducts = async () => {
    const { response } = await AppService.products.getProducts({ query: 'type=super-admin' })
    if (response) {
      setProducts(response.data)
      setprices(response.prices.map((price) => ({
        ...price,
        label: `${price.unit_amount / 100}-${price?.recurring?.interval}-${price.id}-${price?.nickname}`,
        value: price.id,
      })))
    }
    setLoader(false)
  }

  const onLoad = () => {
    getProducts()
  }
  useEffect(onLoad, [])

  const handleClose = () => {
    setOpen(false)
    getProducts()
  }

  return {
    prices: prices,
    columns: [
      // { Header: '', accessor: 'order', width: '10%', align: 'left', verticalAlign: 'baseline' },
      { Header: 'name', accessor: 'name', align: 'left', width: '10%', verticalAlign: 'baseline' },
      {
        Header: 'Price',
        accessor: 'price',
        align: 'left',
        width: '10%',
        verticalAlign: 'baseline',
      },
      {
        Header: 'Product Type',
        accessor: 'product_type',
        align: 'left',
        width: '10%',
        verticalAlign: 'baseline',
      },
      {
        Header: 'limit',
        accessor: 'limit',
        width: '25%',
        align: 'left',
        verticalAlign: 'baseline',
      },
      {
        Header: 'Default Balance',
        accessor: 'default_balance',
        width: '25%',
        align: 'left',
        verticalAlign: 'baseline',
      },
      {
        Header: 'action',
        accessor: 'action',
        align: 'center',
        width: '10%',
        verticalAlign: 'baseline',
      },
    ],

    rows: products.map((item, idx) => ({
      // order: (
      //   <MDBox sx={{ cursor: 'pointer' }}>
      //     <Icon>menu</Icon>
      //   </MDBox>
      // ),
      name: (
        <div>
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {item.name}
          </MDTypography>
        </div>
      ),
      product_type: (
        <div>
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {item.product_type}
          </MDTypography>
        </div>
      ),
      price: (
        <div>
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {item?.price?.id}
          </MDTypography>
        </div>
      ),
      limit: (
        <div>
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {item.limit}
          </MDTypography>
        </div>
      ),
      default_balance: (
        <div>
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {item.default_balance}
          </MDTypography>
        </div>
      ),
      action: (
        <>
          <MDButton
            onClick={() => handleOnClick(item, idx)}
            variant="gradient"
            color="info"
            style={{ marginRight: '10px' }}
          >
            Edit{' '}
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            style={{ marginRight: '10px' }}
            onClick={() => handleDelete(item?._id, idx)}
          >
            delete
          </MDButton>
          {selected == item && (
            <EditProduct product={selected} open={open === idx} onClose={handleClose} prices={prices} />
          )}
        </>
      ),
    })),
    getProducts,
  }
}
