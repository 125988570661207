import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import localforage from 'localforage'
import Card from '@mui/material/Card'
import MDButton from 'components/MDButton'
import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import Alert from '@mui/material/Alert'
import env from 'config'
import { useBrandInfo } from 'context/brand'
import Stack from '@mui/material/Stack'
import ghlLogo from '../../../../assets/images/ghl-logo.avif'
import stripeLogo from '../../../../assets/images/Stripe-Emblem.png'
import { useAgencyInfo } from 'context/agency'
import { useAppServices } from 'hook/services'
import Singleselect from './inputs/Singleselect'
import MDInput from 'components/MDInput'
import Loader from 'examples/Loader'
function Integration() {
  const [brand] = useBrandInfo()
  const [agency] = useAgencyInfo()
  const [settinngs_data, setsettinngs_data] = useState({})
  const [processing, setProcessing] = useState(false)
  const [loader, setloader] = useState(true)
  const [sessionkey, setsessionkey] = useState("");
  const Service = useAppServices()

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      stripe_public_key: e.target.stripe_public_key.value,
      stripe_api_key: e.target.stripe_api_key.value,
    };
    if (settinngs_data?._id) {
      payload._id = settinngs_data._id
    }
    const { response } = await Service.super_admin_settings.create({ payload });
  };
  const getSettingsData = async () => {
    const { response } = await Service.super_admin_settings.get();

    if (response) {
      setsettinngs_data(response.data)
      setloader(false)
    } else {
      setloader(false)

    }

  }
  const onLoad = () => {
    getSettingsData()
  }
  useEffect(() => {
    onLoad()
  }, [])
  return (
    <MDBox pb={3}>
      {
        loader ?
          <Loader />
          :
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Grid container mt={5} mb={2} justifyContent="center">
                <Grid item xs={11}>
                  <MDBox pt={4} px={3}>
                    <div style={{ display: 'flex' }}>
                      <MDBox sx={{ display: "flex", flexDirection: "column", marginRight: '30px' }}>
                        <img src={ghlLogo} alt="logo" width={100} />

                        {settinngs_data?.ghl ? (
                          <MDButton
                            variant="contained"
                            color="info"
                            size="small"
                            sx={{ width: "100px" }}
                          >
                            <a
                              href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.Location_ASSOCIATE}&client_id=${env.GHL.Location_CLIENT_ID}&scope=${env.GHL.Location_SCOPE}&state=${brand.ghl?.location_id}`}
                              style={{ color: "white" }}
                            >
                              Location  Refresh
                            </a>
                          </MDButton>
                        ) : (
                          <MDButton
                            variant="contained"
                            color="info"
                            size="small"
                            sx={{ width: "100px" }}
                          >
                            <a
                              href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.Location_ASSOCIATE}&client_id=${env.GHL.Location_CLIENT_ID}&scope=${env.GHL.Location_SCOPE}&state=${brand.ghl?.location_id}`}
                              style={{ color: "white" }}
                            >
                              Location connect
                            </a>
                          </MDButton>
                        )}

                      </MDBox>

                      <MDBox display="flex" mr={'30px'}>
                        <MDBox sx={{ display: 'flex', flexDirection: 'column' }}>
                          <img src={ghlLogo} alt="logo" width={100} />

                          {settinngs_data?.agency_ghl != undefined ? (
                            <MDButton
                              variant="contained"
                              color="success"
                              size="small"
                              sx={{ width: '100px' }}
                            >
                              <a
                                href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.ASSOCIATE}&client_id=${env.GHL.CLIENT_ID}&scope=${env.GHL.SCOPE}&state=${brand.ghl?.location_id}`}
                                style={{ color: 'white' }}
                              >
                                Agency Refresh
                              </a>
                            </MDButton>
                          ) : (
                            <MDButton
                              variant="contained"
                              color="info"
                              size="small"
                              sx={{ width: '100px' }}
                            >
                              <a
                                href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.ASSOCIATE}&client_id=${env.GHL.CLIENT_ID}&scope=${env.GHL.SCOPE}&state=${brand.ghl?.location_id}`}
                                style={{ color: 'white' }}
                              >
                                Agency connect
                              </a>
                            </MDButton>
                          )}
                        </MDBox>
                      </MDBox>
                    </div>
                    <MDBox mt={4} pb={5} component="form" onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <MDTypography variant="h5">Stripe Integration</MDTypography>
                        </Grid>
                        <Grid item xs={12}>
                          <MDInput
                            style={{ width: '100%' }}
                            label="Public Key"
                            placeholder="public key"
                            name="stripe_public_key"
                            defaultValue={settinngs_data?.stripe_public_key}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MDInput
                            style={{ width: '100%' }}
                            label="Private Key"
                            placeholder="private key"
                            name="stripe_api_key"
                            defaultValue={settinngs_data?.stripe_api_key}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          mt={4}
                          mb={"13rem"}
                          display={"flex"}
                          justifyContent={"flex-start"}
                        >
                          <MDButton variant="contained" color="info" type="submit">
                            save
                          </MDButton>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
      }
    </MDBox >
  )
}

export default Integration
