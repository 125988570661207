import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDModal from 'components/MDModal'
import MDTypography from 'components/MDTypography'
import { useAppServices, useUploadImage } from 'hook/services'
import React, { useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useAgencyInfo } from "context/agency";
import Multiselect from './components/Multiselect'
import { TextareaAutosize } from '@mui/material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 400,
  bgcolor: 'background.paper',
  border: '2px  #000',
  boxShadow: 24,
  p: 4,
}
const DocsValidate = yup.object({
  name: yup.string().required().label('Name'),
})
function OptionCreate({ open, onClose, options, prices }) {
  const [agency, Update] = useAgencyInfo();
  const uploadImage = useUploadImage()
  const [processing, setProcessing] = useState(false)
  const AppService = useAppServices()
  const handleSubmit = async (e) => {
    setProcessing(true)
    e.preventDefault()

    const payload =options?._id? {
      _id:options._id,
      name: e.target.name.value,
    }:{
     
      name: e.target.name.value,
    }
    const { error,response } = await AppService.modalcreate[
      options?._id ? 'update' : 'create'
    ]({
      payload,
      toaster: true,
    })
    if (response) {
      setProcessing(false)
      onClose()
    }else{
      console.log(error)
    }
    setProcessing(false)
    onClose()
  }

  return (
    <MDBox>
      <MDModal
        open={open}
        onClose={onClose}
        height={400}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox role="form" component="form" onSubmit={handleSubmit} sx={style}>
          <MDTypography variant="h6">Create Modal</MDTypography>

          <MDBox my={2}>
            <MDInput
              type="text"
              label="Name"
              name="name"
              defaultValue={options?.name}
              fullWidth
            />
          </MDBox>
        
          
          <MDButton
            variant="gradient"
            color="info"
            type="submit"
            className="brand-card-background"
            loading={processing}
            disabled={processing}
            sx={{ mt: 4, mb: 1 }}
            fullWidth
          >
            {options?.name ? 'Update' : 'Save'}
          </MDButton>
        </MDBox>
      </MDModal>
    </MDBox>
  )
}

export default OptionCreate
