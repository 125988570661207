import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDModal from 'components/MDModal'
import MDTypography from 'components/MDTypography'
import { useAppServices, useUploadImage } from 'hook/services'
import React, { useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useAgencyInfo } from 'context/agency'
import Multiselect from './components/Multiselect'
import RadioInput from 'components/Radio/RadioInput'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 500,
  bgcolor: 'background.paper',
  border: '2px  #000',
  p: 4,
}
const DocsValidate = yup.object({
  name: yup.string().required().label('Name'),
})
function CreateProduct({ open, onClose, product, prices }) {
  const [productType, setproductType] = useState('GHL')

  const [agency, Update] = useAgencyInfo()
  const uploadImage = useUploadImage()
  const product_type = [
    {
      label: `GHL`,
      value: 'GHL',
    },
    {
      label: `Stand Alone`,
      value: 'stand_alone',
    },
  ]
  // console.log(category)
  const [processing, setProcessing] = useState(false)
  const AppService = useAppServices()
  const handleSubmit = async (e) => {
    // setProcessing(true)
    e.preventDefault()

    const payload = {
      name: e.target.name.value,
      price: JSON.parse(e.target.price.value),
      limit: e.target.limit?.value,
      default_balance: e.target.default_balance.value,
      product_type: productType,
      charge_per_api: productType === 'GHL' ? e.target.charge_per_api.value : null,
      type: 'super-admin',
      agency_id: agency._id,
      product_id: e.target.ghl_product_id.value
    }
    console.log(payload)
    // return 0
    if (product) {
      payload._id = product._id
    }

    const { response } = await AppService.products[
      product?._id ? 'updateProduct' : 'createProduct'
    ]({
      payload,
      toaster: true,
    })
    setProcessing(false)
    if (response) {
      onClose()
    }
  }
  const handleType = (value) => {
    setproductType(value)
  }
  return (
    <MDBox>
      <MDModal
        open={open}
        onClose={onClose}
        height={500}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox role="form" component="form" onSubmit={handleSubmit} sx={style}>
          <MDTypography variant="h6">Create Product</MDTypography>

          <MDBox my={2}>
            <MDInput type="text" label="Name" name="name" defaultValue={product?.name} fullWidth />
          </MDBox>
          <MDBox sx={{ fontSize: '15px' }}>
            <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
              Product Type
            </MDTypography>
            <RadioInput options={product_type} name="type" handleChange={handleType} />
            {/* <Multiselect data={prices} edit_data={product?.price || {}} isMulti={false} name="price" /> */}
          </MDBox>
          {productType != 'GHL' && (
            <MDBox my={2}>
              <MDInput
                type="number"
                label="Account Limit"
                name="limit"
                defaultValue={product?.limit}
                fullWidth
              />
            </MDBox>
          )}
          {productType == 'GHL' && (
            <MDBox my={2}>
              <MDInput
                type="text"
                label="Charge Per Api"
                name="charge_per_api"
                defaultValue={product?.charge_per_api ? product?.charge_per_api : 0}
                fullWidth
              />
              {/* <div
                className="css-12n1zae-MuiInputBase-root-MuiOutlinedInput-root"
                style={{ border: '1px solid black' }}
              >
                <input
                  className="css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input"
                  step={0.0001}
                  type="number"
                  label="Charge Per Api"
                  name="charge_per_api"
                  defaultValue={product?.charge_per_api ? product?.charge_per_api : 0}
                  fullWidth
                />
              </div> */}
            </MDBox>
          )}

          <MDBox my={2}>
            <MDInput
              type="number"
              label="Default Balance"
              name="default_balance"
              defaultValue={product?.default_balance ? product?.default_balance : 0}
              fullWidth
            />
          </MDBox>

          <MDBox sx={{ fontSize: '15px' }}>
            <MDInput
              type="text"
              label="GHL Product ID"
              name="ghl_product_id"
              defaultValue={product?.product_id}
              fullWidth
            />
          </MDBox>

          <MDBox my={2} sx={{ fontSize: '15px' }}>
            <Multiselect
              data={prices}
              edit_data={product?.price || {}}
              isMulti={false}
              name="price"
            />
          </MDBox>
          <MDButton
            variant="gradient"
            color="info"
            type="submit"
            className="brand-card-background"
            loading={processing}
            disabled={processing}
            sx={{ mt: 4, mb: 1 }}
            fullWidth
          >
            {product?.name ? 'Update' : 'Save'}
          </MDButton>
        </MDBox>
      </MDModal>
    </MDBox>
  )
}

export default CreateProduct
