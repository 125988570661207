import { Card, Divider, Grid, IconButton, Switch } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import React, { useEffect, useState } from 'react'
import env from 'config'
import axios from 'axios'

const DefaultCustomePrompt = () => {
    const [processing, setProcessing] = useState(false)
    const [supperadminsetting, setSupperadminsetting] = useState({})
    const [prompts, setPrompts] = useState({
        prompt1: '',
        prompt2: '',
        prompt3: '',
        prompt4: '',
        prompt5: '',
    })
    const BASE_URL = `${env.API_URL}/v1`

    useEffect(() => {
        axios.get(`${BASE_URL}/super_admin_settings/`)
            .then((response) => {
                const data = response.data.data
                setSupperadminsetting(data)
                setPrompts({
                    prompt1: data.default_custom_prompt.default_custom_p1 || '',
                    prompt2: data.default_custom_prompt.default_custom_p2 || '',
                    prompt3: data.default_custom_prompt.default_custom_p3 || '',
                    prompt4: data.default_custom_prompt.default_custom_p4 || '',
                    prompt5: data.default_custom_prompt.default_custom_p5 || '',
                })
            })
            .catch((error) => {
                console.error(error)
            })
    }, [BASE_URL])

    const handleChange = (e) => {
        const { name, value } = e.target
        setPrompts((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setProcessing(true)
        try {
            const data = {
                default_custom_prompt: {
                    default_custom_p1: prompts.prompt1,
                    default_custom_p2: prompts.prompt2,
                    default_custom_p3: prompts.prompt3,
                    default_custom_p4: prompts.prompt4,
                    default_custom_p5: prompts.prompt5,
                },
                _id: supperadminsetting._id,
            }
            const response = await axios.put(`${BASE_URL}/super_admin_settings/`, data)
            alert(response.data.message)
        } catch (error) {
            console.error('Failed to update prompts', error)
        } finally {
            setProcessing(false)
        }
    }

    return (
        <MDBox pt={6} pb={3}>
            <Grid item >
                            <MDBox  pb={3} px={3}>
                                <MDBox
                                    component="form"
                                    onSubmit={handleSubmit}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <MDBox mb={2}>
                                        <MDInput
                                            type="text"
                                            label="Prompt 1"
                                            name="prompt1"
                                            value={prompts.prompt1}
                                            onChange={handleChange}
                                            fullWidth
                                            multiline
                                            rows={6}
                                           style={{width:"100%"}}
                                        />
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <MDInput
                                            type="text"
                                            label="Prompt 2"
                                            name="prompt2"
                                            value={prompts.prompt2}
                                            onChange={handleChange}
                                            fullWidth
                                            multiline
                                            rows={6}
                                            style={{width:"100%"}}

                                        />
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <MDInput
                                            type="text"
                                            label="Prompt 3"
                                            name="prompt3"
                                            value={prompts.prompt3}
                                            onChange={handleChange}
                                            fullWidth
                                            multiline
                                            rows={6}
                                            style={{width:"100%"}}

                                        />
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <MDInput
                                            type="text"
                                            label="Prompt 4"
                                            name="prompt4"
                                            value={prompts.prompt4}
                                            onChange={handleChange}
                                            fullWidth
                                            multiline
                                            rows={6}
                                            style={{width:"100%"}}

                                        />
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <MDInput
                                            type="text"
                                            label="Prompt 5"
                                            name="prompt5"
                                            value={prompts.prompt5}
                                            onChange={handleChange}
                                            fullWidth
                                            multiline
                                            rows={6}
                                        />
                                    </MDBox>

                                    <MDBox>
                                        <MDButton
                                            variant="gradient"
                                            color="info"
                                            type="submit"
                                            sx={{ mt: 4, mb: 1 }}
                                            disabled={processing}
                                            fullWidth
                                        >
                                            {processing ? 'Updating...' : 'Update'}
                                        </MDButton>
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </Grid>
        </MDBox>
    )
}

export default DefaultCustomePrompt
