/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Drawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'

export default styled(Drawer)(({ theme, ownerState }) => {
  const { palette, boxShadows, transitions, breakpoints, functions } = theme
  const { transparentSidenav, whiteSidenav, miniSidenav, darkMode } = ownerState

  const sidebarWidth = 250
  const { transparent, gradients, white, background } = palette
  const { xxl } = boxShadows
  const { pxToRem, linearGradient } = functions
    var value= getComputedStyle(document.documentElement).getPropertyValue('--ThemesidnavBg')

  let backgroundValue = value
  // if (transparentSidenav) {
  //   backgroundValue = transparent.main
  // } else if (whiteSidenav) {
  //   backgroundValue = white.main
  // }

  // styles for the sidenav when miniSidenav={false}
  const drawerOpenStyles = () => ({
    background : backgroundValue,
    transform  : 'translateX(0)',
    transition : transitions.create('transform', {
      easing   : transitions.easing.sharp,
      duration : transitions.duration.shorter,
    }),

    [breakpoints.up('xl')]: {
      boxShadow    : transparentSidenav ? 'none' : xxl,
      marginBottom : transparentSidenav ? 0 : 'inherit',
      left         : pxToRem(274),
      width        : sidebarWidth,
      transform    : 'translateX(0)',
      transition   : transitions.create(['width', 'background-color'], {
        easing   : transitions.easing.sharp,
        duration : transitions.duration.enteringScreen,
      }),
    },
  })

  // styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    background : backgroundValue,
    transform  : `translateX(${pxToRem(-320)})`,
    transition : transitions.create('transform', {
      easing   : transitions.easing.sharp,
      duration : transitions.duration.shorter,
    }),

    [breakpoints.up('xl')]: {
      boxShadow    : transparentSidenav ? 'none' : xxl,
      marginBottom : transparentSidenav ? 0 : 'inherit',
      left         : pxToRem(274),
      width        : pxToRem(96),
      overflowX    : 'hidden',
      transform    : 'translateX(0)',
      transition   : transitions.create(['width', 'background-color'], {
        easing   : transitions.easing.sharp,
        duration : transitions.duration.shorter,
      }),
    },
  })

  return {
    '& .MuiDrawer-paper': {
      boxShadow : xxl,
      border    : 'none',

      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },
  }
})
